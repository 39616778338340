import React from 'react';
import Logo from '../assets/images/footerLogo.png';

// const maps = new Map([
//     ['/myquiz/quizdetail', '/quizdetail'],
//     ['/onlineconsultation', '/gamechoose'],
//     ['/onlineconsultationim', '/'],
//     ['/accountproblems', '/quikform'],
//     ['/accountproblems/accountdetail', '/gamechoose'],
//     ['/accountproblems/accountcontent', '/nofkeycontent']
// ]);

// const switchQuery = (query, before, after) => {
//     return query.replace(before, after);
// };

// const switchToPhone = () => {
//     let location = window.location;
//     let name = maps.get(location.pathname);
//     let query = location.search;
//     if (name) {
//         if (name === '/quikform') {
//             query = switchQuery(query, 'accountid', 'id');
//         }
//         if (name === '/nofkeycontent') {
//             query = switchQuery(query, 'fid', 'id');
//         }
//         window.location.href = `//h5.gm.game.360.cn${name}${query}`;
//         return;
//     }
//     window.location.href = `//h5.gm.game.360.cn${location.pathname}${query}`;
// };

// if(/Android|webOS|iPhone|iPod|iPad/i.test(navigator.userAgent)) {
//     switchToPhone();
// }
const Footer = () => {
    return (
        <section>
            <style jsx>{`
             .footer {
                width: 100%;
                height: 98px;
                background: #01080f;
                display: flex;
                font-size: 12px;
                min-width: 1200px;
            }
            .footer-content {
                position: relative;
                padding-top:22px;
                min-width: 1200px;
                margin: 0 auto;
                color: #fff;
            }
            .footer-content a {
                color: #fff;
            }
            .footer-content a:hover {
                color: #D0021B;
            }
            .logo {
                position: absolute;
                left: 0;
                top: 53%;
                transform: translateY(-50%);
            }
            .logo img{
                width:280px;
                height:34px;
            }
            .negotiation a, .negotiation button {
                white-space: nowrap !important;
                margin-left: 10px;
                // cursor: pointer;
            }
            .negotiation {
                // margin-left: 450px;
                position: absolute;
                right: 0;
            }
            .top {
                // width: 600px;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
            .center {
                // width: 600px;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
            .bottom {
                display: flex;
                flex-direction: row-reverse;
            }
            .switchToPhone {
                color: #999090;
            }
         `}
            </style>
            <section className="footer">
                <div className="footer-content">
                    <div className="logo">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="negotiation">
                        <div className="top">
                            <a href="https://wg.360.cn/kf/legal/EULA" target="_blank" rel="noopener noreferrer">360游戏服务及许可协议</a>
                            {/* <a href="mailto:game@360.cn">游戏商务合作</a> */}
                            <a href="http://mis.h5.u.360.cn/wcn/index.htm" target="_blank" rel="noopener noreferrer">游戏组件未成年人家长监护工程</a>
                            <a href="http://p0.qhimg.com/t0169dceb0c0d596fcd.jpg" target="_blank" rel="noopener noreferrer">北京世界星辉科技有限责任公司</a>
                        </div>
                        <div className="center">
                            <a href="http://s5.ssl.qhres.com/static/b6e17859264cd813.pdf" target="_blank" rel="noopener noreferrer">京ICP证京B2-20170671</a>
                            <button>京ICP备11029981号-10</button>
                            <a href="http://p1.ssl.qhimg.com/d/inn/4be9e4098b53/txz.jpg" target="_blank" rel="noopener noreferrer">京网文【2020】0038-009</a>
                            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502035516" target="_blank" rel="noopener noreferrer">京公网安备 11010502035516号</a>
                        </div>
                        <div className="bottom">
                            <button>Copyright<span role="img" aria-label="">©️</span>2012 – 2020 Wargaming.net保留所有权利 360客户端游戏中心</button>
                            {/* <button className="switchToPhone" onClick={switchToPhone}>切换至手机版</button> */}
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Footer;