import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchData } from 'helpers';

const Report = props => {
    const { title, time, top } = props;
    const style = {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        position: 'relative',
        // paddingLeft: '10px',
        marginTop: '12px',
    };
    return (
        <section style={style}>
            <style jsx>{`
                .report-content {
                    color: ${top === '1' ? '#D0021B' : '#fff'};
                    width: 300px;
                    overflow: hidden;
                    padding-left: 8px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    flex: 1;
                    line-height: 1.4;
                }
                .report-date {
                    font-size: 12px;
                }
            `}</style>
            <p className="report-content add-pointer">{title}</p>
            <p className="report-date">{time}</p>
        </section>
    );
};

const toNoticeDetail = (history, id) => {
    history.push(`/announcement?noticeId=${id}`);
};

// 公告板块
const Announcement = props => {
    const [noticeList, setNoticeList] = useState([]);
    useEffect(() => {
        fetchData('getNoticeList')
            .then(res => {
                setNoticeList(res.data);
            })
            .catch(err => {});
    }, []);
    return (
        <section>
            <style jsx>{`
                .announcement {
                    width: 476px;
                    height: 208px;
                    background: #4e4238;
                }
                .title-fence {
                    height: 40px;
                    background: #443a32;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #fff;
                }
                .report-list {
                    height: 168px;
                    padding: 0 15px 15px;
                    box-sizing: border-box;
                    overflow-y: auto;
                    color: #fff;
                }
            `}</style>
            <section className="announcement border-common">
                <div className="title-fence">
                    <span style={{ fontWeight: 'bold', marginLeft: '6px', fontSize: '16px' }}>公告</span>
                </div>
                <ul className="report-list">
                    {noticeList &&
                        noticeList.map(item => {
                            return (
                                <li key={item.id} onClick={() => toNoticeDetail(props.history, item.id)}>
                                    <Report {...item} />
                                </li>
                            );
                        })}
                </ul>
            </section>
        </section>
    );
};

export default withRouter(Announcement);
