import React from 'react';
import CenterTitle from 'components/centerTitle';
import routes from 'routes';
import { Route } from "react-router-dom";
import { connect } from 'react-redux';

// 用来判断到底是哪个大类的导航
// let classTransfer = new Map([
//   ['1', '登录问题'],
//   ['2', '游戏问题'],
//   ['3', '充值问题'],
//   ['4', '账号问题'],
//   ['5', '投诉'],
// ])
// @params 递归函数 找到的匹配的路由对象 面包屑
const findBreads = (routes, pathnames, routeObj) => {
    if (!Array.isArray(pathnames)) {
        pathnames = pathnames.split('/');
    }
    pathnames = pathnames.slice(1);
    const components = routes.find(item => {
        const paths = item.path.split('/');
        const path = paths[paths.length - 1];
        return path === pathnames[0];
    });
    if (pathnames.length === 1) {
        routeObj.breads.push(components.title);
        routeObj.paths.push(components.path);
        routeObj.component = components.component;
        return routeObj;
    } else {
        routeObj.breads.push(components.title);
        routeObj.paths.push(components.path);
        return findBreads(components.routes, pathnames, routeObj);
    }
};

const DetailServices = (props) => {
    const { location } = props.history;
    if (location.pathname === '/onlineconsultationim') {
        props.history.push(`/`);
        return null;
    }
    let accountId = String(window.sessionStorage.getItem('accountId') ? window.sessionStorage.getItem('accountId') : '1');
    let questionBreads = JSON.parse(window.sessionStorage.getItem('questionBreads'));

    let routeObj = {
        breads: ['首页'],
        paths: ['/'],
        component: '',
    };

    const Components = findBreads(routes, location.pathname, routeObj);
    let { breads } = Components;
    if (breads[1] && breads[1] === '账号问题') {
        if (questionBreads) {
            let title = questionBreads.find(i => i.id === accountId).title;
            breads[1] = title;
        }
    }

    return (
        <section>
            <style jsx>{`
                .center-container {
                    width: 962px;
                }
            `}</style>
            <section className="center-container">
                <CenterTitle breads={Components.breads} paths={Components.paths} history={props.history} />
                <Route component={Components.component} />
            </section>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        routeName: state.formReducer.routeName,
    };
}
export default connect(mapStateToProps)(DetailServices);