import React, { useState, useEffect, Fragment } from 'react';
import { fetchData } from 'helpers';
import titleBg1 from '../../assets/images/title_bg1.jpg';
import titleBg2 from '../../assets/images/title_bg2.jpg';
import titleBg3 from '../../assets/images/title_bg3.jpg';
import titleBg4 from '../../assets/images/title_bg4.jpg';
import titleBg5 from '../../assets/images/title_bg5.jpg';

const toMore = (history, id) => {
    window.sessionStorage.setItem('accountId', id);
    history.push(`/accountproblems?accountid=${id}`);
};

const toQuestionDetail = (history, fkey, id, accountId, title) => {
    window.sessionStorage.setItem('accountId', accountId);
    if (fkey) {
        history.push(`/accountproblems/accountdetail?fkey=${fkey}&quizTitle=${title}`);
        // history.push(`/onlineconsultation?fkey=${fkey}`);
    } else {
        history.push(`/accountproblems/accountcontent?fid=${id}`);
    }
};

const titleBG = [titleBg1, titleBg2, titleBg3, titleBg4 ,titleBg5];

const SingleConsult = props => {
    const { title, questionList, titleBG, id, history } = props;
    return (
        <Fragment>
            <style jsx>{`
                .box {
                    width: 230px;
                    height: 298px;
                    box-sizing: border-box;
                    background: #4e4238;
                    position: relative;
                    overflow: hidden;
                    margin-right: 12px;
                    margin-top: 10px;
                    z-index: 50;
                }
                .box:nth-child(4n + 0) {
                    margin-right: 0;
                }
                .type-show {
                    height: 100px;
                    line-height: 100px;
                    background: url(${titleBG});
                    font-size: 20px;
                    color: white;
                    font-weight: bold;
                    text-align: center;
                }
                .quiz-list {
                    font-size: 14px;
                    margin-top: 10px;
                    color: #fff;
                }
                .quiz {
                    position: relative;
                    padding-left: 15px;
                    margin-bottom: 8px;
                }
                .more {
                    width: 82px;
                    height: 40px;
                    background: #602f0f;
                    color: #fff;
                    line-height: 40px;
                    text-align: center;
                    position: absolute;
                    right: -10px;
                    bottom: -1px;
                    transform: skew(-20deg);
                }
                .more span {
                    display: inline-block;
                    transform: skew(20deg);
                }
            `}</style>
            <div className="box border-common">
                <h1 className="type-show">{title}</h1>
                <ul className="quiz-list">
                    {questionList.map((item, index) => {
                        return (
                            <li
                                className="quiz add-pointer"
                                key={index}
                                onClick={() => toQuestionDetail(history, item.fkey, item.id, id, item.title)}
                            >
                                <p className="quiz-content">{item.title}</p>
                            </li>
                        );
                    })}
                </ul>
                <div className="more add-pointer border-common" onClick={() => toMore(history, id)}>
                    <span>更多</span>
                </div>
            </div>
        </Fragment>
    );
};

const AllKindsConsult = props => {
    const [questionList, setQuestionList] = useState([]);
    useEffect(() => {
        fetchData('getQuestionCateList')
            .then(res => {
                setQuestionList(res.data);
                let breads = res.data.map(item => {
                    return {
                        id: item.id,
                        title: item.title,
                    };
                });
                window.sessionStorage.setItem('questionBreads', JSON.stringify(breads));
            })
            .catch(err => {});
    }, []);
    return (
        <section>
            <style jsx>{`
                .box {
                    display: flex;
                    flex-wrap: wrap;
                }
            `}</style>
            <section className="box">
                {questionList.map((item, index) => {
                    return (
                        <SingleConsult
                            key={index}
                            title={item.title}
                            id={item.id}
                            history={props.history}
                            questionList={item.question_list}
                            titleBG={titleBG[index]}
                        />
                    );
                })}
            </section>
        </section>
    );
};

// function mapStateToProps(state) {
//   return {
//     questionBreads: state.questionReducer.questionBreads,
//   }
// }

export default AllKindsConsult;
