import React, { useEffect, useState } from 'react';
import { fetchData } from 'helpers';
import iconAlert from '../../../assets/images/icon-alert.png';
import iconCorrect from '../../../assets/images/icon-correct.png';
import iconDanger from '../../../assets/images/icon-danger.png';
import iconDownload from '../../../assets/images/icon-download.png';
import iconEdit from '../../../assets/images/icon-edit.png';
import iconFace from '../../../assets/images/icon-face.png';
import iconMoney from '../../../assets/images/icon-money.png';
import iconPhone from '../../../assets/images/icon-phone.png';
import iconServer from '../../../assets/images/icon-server.png';

const SingleProblem = props => {
    const { title, description, iconName } = props;
    const style = {
        width: '460px',
        height: '94px',
        display: 'flex',
        alignItems: 'center',
    };
    return (
        <section style={style} className="add-pointer">
            <style jsx>{`
                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 20px;
                    width: 50px;
                    height: 50px;
                }
                .content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 50px;
                    margin-left: 10px;
                }
                .title {
                    font-weight: 600;
                    font-size: 20px;
                    color: #fff;
                }
                .explaination {
                    color: #fff;
                }
            `}</style>
            <i className="icon">
                <img src={iconName} alt="" />
            </i>
            <div className="content">
                <p className="title">{title}</p>
                <p className="explaination">{description}</p>
            </div>
        </section>
    );
};

const toAccountDetail = (history, fkey, id, quizTitle) => {
    if (fkey) {
        history.push(`/accountproblems/accountdetail?fkey=${fkey}&quizTitle=${quizTitle}`);
        // history.push(`/onlineconsultation?fkey=${fkey}&quizTitle=${quizTitle}`);
    } else {
        history.push(`/accountproblems/accountcontent?fid=${id}&quizTitle=${quizTitle}`);
    }
};
let randoms = [];
function getRandom(min, max) {
    let num = Math.floor(Math.random() * (max - min)) + min;
    if (randoms.includes(num)) {
        return getRandom(min, max);
    }
    randoms.push(num);
    if (randoms.length > max - 1) {
        randoms = [];
    }
    return num;
}

const AccountProblems = props => {
    const accountId = new URLSearchParams(props.location.search).get('accountid');
    const [questionList, setQuestionList] = useState([]);
    useEffect(() => {
        fetchData('getQuestionList', { id: accountId })
            .then(res => {
                let list = res.data.question_list;
                setQuestionList(list);
            })
            .catch(err => {});
    }, [accountId]);
    const style = {
        minHeight: '500px',
    };
    let iconNames = [
        iconAlert,
        iconCorrect,
        iconDanger,
        iconDownload,
        iconEdit,
        iconFace,
        iconMoney,
        iconPhone,
        iconServer,
    ];
    return (
        <section style={style}>
            <style jsx>{`
                .contents {
                    overflow: hidden;
                }
                .content {
                    margin-bottom: 20px;
                    margin-right: 18px;
                    transition: all 0.3s;
                }
                .content:hover {
                    background: linear-gradient(to right, #ac8f7a, rgba(0, 0, 0, 0));
                }
            `}</style>
            <ul className="contents">
                {questionList &&
                    questionList.map((item, index) => {
                        let iconNum = getRandom(0, iconNames.length);
                        return (
                            <li
                                className="content fl border-common bg-common"
                                key={item.id}
                                onClick={() => toAccountDetail(props.history, item.fkey, item.id, item.title)}
                            >
                                <SingleProblem {...item} iconName={iconNames[iconNum]} />
                            </li>
                        );
                    })}
            </ul>
        </section>
    );
};

export default AccountProblems;
