import React, { useEffect, useState } from 'react';
import { Table, ConfigProvider } from 'antd';
import EvaluateModal from './evaluateModal';
import { Link } from 'react-router-dom';
import { fetchData } from 'helpers';
import iconAnchor from 'assets/images/icon-anchor.png';
// import QuizDetail from './quizDetail';

const SetAction = (text, props) => {
    const style = {
        display: 'flex',
        flexWrap: 'wrap',
    };
    // let quizTitle=encodeURIComponent(props.title);
    const linkUrl = `/myquiz/quizdetail?num=${props.num}&quizTitle=${props.title}`;
    return (
        <section style={style}>
            <div className="add-pointer" style={{ marginRight: '8px' }}>
                <Link to={linkUrl}>查看</Link>
            </div>
            <div className="add-pointer control-eva">
                <EvaluateModal num={props.num} isAssess={props.is_assess} />
            </div>
            {/* <div className="add-pointer" style={{color: '#999'}}>投诉</div> */}
        </section>
    );
};

const columns = [
    {
        title: '问题编号',
        dataIndex: 'num',
        key: 'num',
        width: 200,
        className: 'changeTdStyle',
    },
    {
        title: '问题类别',
        dataIndex: 'category',
        key: 'category',
        width: 239,
        className: 'changeTdStyle',
    },
    {
        title: '问题标题',
        dataIndex: 'title',
        key: 'title',
        width: 239,
        className: 'changeTdStyle',
    },
    {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        className: 'changeTdStyle',
        width: 114,
    },
    {
        title: '当前状态',
        dataIndex: 'status',
        key: 'status',
        width: 82,
        className: 'changeTdStyle status-style',
        render: (text) => {
            return (
                <React.Fragment>
                    <i
                        style={{
                            color: text === '新回复' ? '#00ffae' : '#fff',
                        }}
                    >
                        {text}
                    </i>
                </React.Fragment>
            );
        },
    },
    {
        title: '操作',
        dataIndex: 'action',
        width: 82,
        key: 'action',
        className: 'changeTdStyle',
        render: SetAction,
    },
];

const customizeRenderEmpty = () => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '580px',
        }}
    >
        <img src={iconAnchor} style={{ marginBottom: '20px' }} alt="" />
        <p style={{ color: '#fff' }}>目前没有问题记录</p>
    </div>
);

const Myquiz = () => {
    const [dataSource, setDataSource] = useState([]);
    const [total, setTotal] = useState(0);
    // const [evaStatus, setEvaStatus] = useState('0');
    const pageChange = (page) => {
        fetchData('getFormQuestionList', {
            start: (page - 1) * 6,
            limit: 6,
        }).then((res) => {
            if (res) {
                setDataSource(res.data.list);
            }
        });
    };
    useEffect(() => {
        let isSubscribed = true;
        fetchData('getFormQuestionList', { start: 0, limit: 6 }).then((res) => {
            if (res && isSubscribed) {
                setDataSource(res.data.list);
                setTotal(res.data.total);
            }
        });
        return () => (isSubscribed = false);
    }, []);
    return (
        <section>
            <style>{`
                tr:nth-child(2n+1) {
                    background: rgba(255, 255, 255, .03);
                }
                tr:nth-child(2n) {
                    background: rgba(0, 0, 0, .03);
                }
                .ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
                    background: rgba(255, 255, 255, .1);
                }
                .status-style {
                    color: #13a1c3;
                }
                .myquiz-table .ant-table-thead>tr>th {
                    padding: 8px 12px;
                    background: #01080f;
                    color: #fff;
                    border-right: none;
                    font-weight: bold;
                    border: none;
                }
                .myquiz-table .ant-table-thead > tr:first-child > th:first-child,
                .myquiz-table .ant-table-thead > tr:first-child > th:last-child {
                    border-radius: 0;
                }
                .myquiz-table .ant-table-tbody .changeTdStyle {
                    padding: 8px 12px;
                }
                .myquiz-table .ant-pagination-item-active {
                    border-color: #13a1c3;
                }
                .myquiz-table .ant-pagination-item-active a {
                    color: #13a1c3;
                }
                .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
                    background: #FFF2F2;
                }
                .ant-pagination-item:focus, .ant-pagination-item:hover {
                    border-color: #13a1c3;
                    color: #13a1c3;
                }
                .ant-pagination-item:focus a, .ant-pagination-item:hover a {
                    color: #13a1c3;
                }
                .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
                    border-color: #13a1c3;
                    color: #13a1c3;
                }
                .ant-pagination-disabled a, .ant-pagination-disabled:hover a, .ant-pagination-disabled:focus a, .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
                    color: rgba(0, 0, 0, 0.25);
                    border-color: #d9d9d9;
                    cursor: not-allowed;
                }
                .ant-table-wrapper.myquiz-table .ant-table-placeholder {
                    background: none;
                    border: none;
                }
                .ant-table-wrapper.myquiz-table .ant-table table {
                    border: none;
                }
                .myquiz-table .ant-table {
                    color: #fff;
                }
                .ant-table-bordered .ant-table-tbody > tr > td {
                    border: none;
                }
                .ant-table-pagination.ant-pagination {
                    margin-right: 20px;
                }
            `}</style>
            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                    rowKey={(record) => record.num}
                    dataSource={dataSource}
                    bordered
                    className="myquiz-table border-common bg-common"
                    columns={columns}
                    pagination={{
                        total,
                        pageSize: 6,
                        onChange: pageChange,
                    }}
                />
            </ConfigProvider>
        </section>
    );
};

export default Myquiz;
