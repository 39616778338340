import React, { useState, useEffect } from 'react';
import { fetchData } from 'helpers';

const AccountContent = (props) => {
    const id = new URLSearchParams(props.location.search).get('fid');
    const style = {
        minHeight: '495px',
        padding: '30px 17px',
    };
    const [data, setData] = useState({});
    useEffect(() => {
        fetchData('getQuestionStaticDetail', { id, }).then((res) => {
            setData(res.data);
        });
    }, [id]);
    return (
        <section className="border-common bg-common" style={style}>
            <style>{`
                .account-content img {
                    max-width: 100%;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
                .account-content p {
                    color: #fff !important;
                }
            `}</style>
            <style jsx>{`
                .title {
                    font-size: 16px;
                    margin-bottom: 20px;
                    color: #fff;
                }
                .account-content {
                    line-height: 24px;
                    color: #fff !important;
                }
            `}</style>
            <h4 className="title">{data.title}</h4>
            <div className="account-content" dangerouslySetInnerHTML={{ __html: data.content }} />
        </section>
    );
};

export default AccountContent;