import React, { useEffect, useState } from 'react';
import { fetchData } from 'helpers';
import iconHelp from '../../../assets/images/icon-help.png';

const LiSingle = props => {
    const { title, content, i, showNum, fn } = props;
    return (
        <section>
            <style jsx>
                {`
                    .question {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;
                        color: #fff;
                    }
                    .title {
                        margin-left: 8px;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .question-content {
                        padding: 15px 20px;
                        display: ${i === showNum ? 'block' : 'none'};
                        color: #fff;
                    }
                    .question-content :global(img) {
                        max-width: 100%;
                    }
                `}
            </style>
            <div className="question add-pointer" onClick={() => fn(i)}>
                <i className="icon">
                    <img src={iconHelp} alt="" />
                </i>
                <p className="title">{title}</p>
            </div>
            <div
                className="question-content"
                dangerouslySetInnerHTML={{ __html: content }}
                style={{ whiteSpace: 'pre-wrap' }}
            ></div>
        </section>
    );
};

const HotQuiz = props => {
    const quizIndex = new URLSearchParams(props.location.search).get('quizindex');
    const [showNum, setShowNum] = useState(Number(quizIndex));
    const [noticeList, setNoticeList] = useState([]);
    useEffect(() => {
        fetchData('getFaqList', { limit: 0, gkey: 'wotol' })
            .then(res => {
                setNoticeList(res.data);
            })
            .catch(err => {});
    }, []);

    const itemClick = index => {
        let item = noticeList[index];
        item && fetchData('postFaqStatistics', { id: item.id });
        setShowNum(index);
    };

    const style = {
        width: '100%',
        padding: '15px 18px 30px',
        boxSizing: 'border-box',
    };
    return (
        <section className="bg-common border-common" style={style}>
            <style jsx>{`
                .quiz {
                    margin-top: 15px;
                }
            `}</style>
            <ul className="content">
                {noticeList.map((item, index) => {
                    return (
                        <li className="quiz" key={index}>
                            <LiSingle {...item} i={index} showNum={showNum} fn={itemClick} />
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

export default HotQuiz;
