export default {
    login: [
        {
            question: '战游平台无法登录，显示：账号已过期或密码错误',
            answer: `您好，请使用战游平台登录游戏`,
        },
        {
            question:
                '战游平台无法登录，无提示或登录页面英文报错（Critical  Error  Occurred或Critical  Error）',
            answer: `您好，建议您把系统默认浏览器更换成“360浏览器极速模式”尝试，同时建议您使用网游加速器改善网络环境进行重新安装，如仍无法连接请使用360断网急救箱、然后用修复LSP工具并重启电脑后再次尝试，祝您生活愉快。`,
        },
        {
            question: '无法登录，登录页面提示需授权',
            answer: `您好，建议您把系统默认浏览器更换成“360浏览器极速模式”尝试，如仍无法连接请使用360断网急救箱、然后用修复LSP工具并重启电脑后再次尝试，祝您生活愉快。`,
        },
        {
            question: '如何更换账号登录',
            answer: `您好，切换不同账号登录，请使用战游平台点击左上角登录后，选择“添加账号”登录对应大区的360账号即可登录，祝您生活愉快！`,
        },
    ],
    game: [
        {
            question: '如何修改游戏昵称',
            answer: `请您登录官网，点击右上角登录，账号管理，更改，进行修改昵称操作
https://wgn.wggames.cn/personal/nickname_change/
请您注意：
1 昵称创建不得使用使用涉及黄色、暴力、军事、政治等敏感词。一经发现您的账号将会被永久封停
2 每次修改昵称至少间隔14天
3 修改昵称需要花费金币`,
        },
        {
            question: '游戏过程中出现卡顿，延迟，闪退等情况',
            answer: `请先卸载第三方插件后，使用网游加速器改善网络环境并使用纯净版客户端登录尝试，如仍无法连接请使用360断网急救箱功能尝试修复网络 `,
        },
        {
            question: '游戏卡在战斗结算页面无法回到港口',
            answer: `请先卸载第三方插件后，使用网游加速器改善网络环境并使用纯净版客户端登录尝试，如仍无法连接请使用360断网急救箱功能尝试修复网络 `,
        },
    ],
    recharge: [
        {
            question: '如何购买礼包',
            answer: `请点击：https://shop.wows.360.cn/进入商城选择对应礼包，或游戏内选择军械库选择对应礼包购买`,
        },
        {
            question: '游戏内充值未到账怎么办？',
            answer: `由于网络波动等原因，充值可能会有15-30min的充值延迟，请您耐心等待，并重新登录游戏查看
如果充值超过30分钟，如后续仍未到账，请前往：https://kf.wg.360.cn/选择充值问题中的“充值未到账进行申诉，祝您审过愉快！
注：申请转移时只能将礼包的金币，银币，游戏道具转移，礼包里面的任务是无法转移的，在申请转移前请先登录冲值错账号里面核实购买道具是否到账，只有礼包到账后才可申请转移，如果冲值错账号未登录过无法转移。如果账号被封停或者争议账号无法操作转移。`,
        },
        {
            question: '购买礼包（充值）错账号',
            answer: `请前往：https://kf.wg.360.cn/选择充值问题内”充错账号物品转移“申诉`,
        },
        {
            question: '支付宝/微信第三方扣款截图删除如何再次获取',
            answer: `【支付宝删记录查询方法】：
1.如果您的支付宝记录已经删除，请您使用电脑登录支付宝-点击交易记录-右上角回收站-找到对应的支出详情-点击详情后截图（截图需包含完整的交易号、时间、金额）然后将截图添加附件发送给我们以便我们核实处理。
2.如果您的支付宝支出记录回收站被清空，您可以联系支付宝在线客服（支付宝-我的客服-交易记录-如何查看交易记录-向我提问）告知客服查询已删除回收站的支出记录查询，告知客服具体交易时间，然后索要19位数的订单号，提供给我们。
【微信删除记录查询方法】：
微信充值记录已删的情况下，您可以联系微信客服申请找回已删除的充值记录。（打开微信搜索【微信支付】公众号，点击【其他帮助】—【更多问题】—【联系客服】打开微信【我】—点击【支付】—【钱包】—点击【帮助中心】—【联系客服】）以上联系微信客服找回充值记录方式仅供参考。
`,
        },
        {
            question: '游戏内购买礼包详细说明',
            answer: `在游戏内选择要冲值的物品或金币——弹出到网页——登录账号（如果默认登录要核实下昵称是否和游戏内昵称一致，如果不一致请退出重新登录）——付款页面——选择付款方式（注一笔订单只能支付一次，如果微信支付了就不要再用支付宝去重复支付）——支付成功`,
        },
        {
            question: '商城内购买礼包详细说明',
            answer: `打开官网——官方商城（这时要看一下右上角登录的昵称如果不是自己游戏昵称请点击退出重新登录自己的账号）——选择想购买的礼包——购买（这时会弹到支付页面会弹出提示是否是自己的游戏昵称如果不是请选择切换账号）——支付成功`,
        },
    ],
    platform: [
        {
            question: '360平台充值有折扣吗',
            answer: `平台会不定期开展平台活动，建议您多多留意我们的活动信息`,
        },
        {
            question: '白金卡取消连续包月方法',
            answer: `如果您使用支付宝请参考如下操作：
1.在支付宝中【设置】-【支付设置】-【免密支付/自动扣款】选择需要取消（解约）的项目
2.在游戏内点击【浮窗】-【钱包】-【设置】，里面有管理支付宝免密支付。`,
        },
        {
            question: '如何获得手游礼包',
            answer: `礼包查询方法如下：
1.开浏览器-地址栏输入http://ka.u.360.cn/进入福利360游戏大厅-在搜索栏输入您所玩的游戏-查找您想要的礼包即可
2.您还可以登录http://u.360.cn/然后搜索您要玩的游戏，然后点击礼包，然后查找您需要的礼包
3.点击游戏内【悬浮窗】-【礼包】可选择VIP等级礼包
4.点击【360游戏大厅】-【我】-【有奖任务】可进行查看是否有对应游戏礼包
手游礼包码如何使用：
进入游戏-找到礼包或设置图标点击打开-输入礼包码即可；或者在悬浮窗-福利-代金券-兑换码输入即可`,
        },
        {
            question: '积分商城实物兑换及虚拟兑换发货时间',
            answer: `您好，实物兑换商品在形成兑换订单后二十个工作日发货（周六日、国家法定假期不包含在工作日内）虚拟兑换商品原则是实时收到，但由于各游戏应用的不同或系统问题，可能有延迟，请您耐心等待。如您在等待过程出有其他问题，可在客服中心首页最下方【活动问题】栏中，对应的表单并点击提交，我们接收后，会第一时间为您处理。`,
        },
        {
            question: '如何关闭手游悬浮窗',
            answer: `关闭方法：
            仅限新版悬浮窗可以进行设置的打开【悬浮窗】——【个人】——【头像下面第三个选项隐藏悬浮球】即可隐藏悬浮球了，目前重新登录后都需要再次操作进行隐藏。`,
        },
        {
            question: '积分/云钻竞拍规则',
            answer: `竞拍规则如下：
1.竞拍结束后根据商品数量来确定竞拍成功的用户，例如商品数量为10，前10名出价的用户可中标
2.出价相同的用户，先出价成功的用户排名靠前
3.出价成功后积分/云钻将会冻结
4.竞拍中，出价用户被淘汰,可取消竞拍，取消后积分/云钻返回该用户360积分商城账户
5.竞拍结束后，未竞拍成功的用户积分/云钻会返回该用户360积分商城账户
6.竞拍结束后，竞拍成功的用户积分/云钻将被扣除,无论是否领取奖品,积分/云钻均不退回。
7.如果是需要支付金额的商品,竟拍成功后无论是否支付购买,所有消耗的积分/云钻均不退回
8.中标后请在活动时间内支付,否则视为放弃购买资格。
9.积分竞拍：缴纳的门票费不会退回。`,
        },
        {
            question: '忘记360币支付密码，如何重置',
            answer: `1. 浏览器登录pay.360.cn，登录自己的360账号后，点击【我的账户】— 点击【修改支付密码】— 点击【忘记密码】— 输入设置的安全问题答案— 设置新密码— 确认新密码— 点击【确定】即可保存成功
2. 如果您将安全问题答案一并忘记了，需要联系客服处理;`,
        },
        {
            question: '如何设置360币支付密码/安全问题',
            answer: `1.浏览器登录pay.360.cn，登录自己的360账号后，点击【我的账户】，输入登录密码— 设置支付密码— 确认支付密码 — 设置安全问题 — 输入安全问题答案— 输入账号下绑定的密保手机收到的验证码— 点击【确定】即可设置成功
2. 如果您将安全问题答案一并忘记，需联系工作人员处理`,
        },
        {
            question: '如何取消支付宝免密支付',
            answer: `操作如下：打开支付宝点击【我的】——【设置】——【支付设置】——【免密支付/自动扣款】取消您的设置即可`,
        },
        {
            question: '游戏大厅切换极速模式一直白屏、加载慢',
            answer: `1.CPU/内存占用满的情况下会有机率出现无法加载而导致一直白屏的情况，关闭游戏标签重新打开即可。
2.首次切换极速模式加载耗时相对普通打开界面的时间稍长，二次切换加载速度会有所提升`,
        },
        {
            question: '游戏大厅游戏卡加载、卡顿、黑屏、白屏或掉线',
            answer: `使用360浏览器并切换到极速模式，若正常则是缓存问题。 大厅切换兼容/极速模式，只能勾选一个。
可以分别尝试修复（大厅任务栏中“修复”）：
极速版：一键修复，勾选关闭后彻底清理，重启游戏大厅。 
兼容版：一键修复。 
若浏览器极速模式下出现同样的问题，则是网络问题。
1.可尝试优化DNS（可通过360安全卫士中的DNS修复功能进行操作）
2.您可以在客服中心首页最下方【游戏问题】栏中，点击【闪退、卡顿、掉线】（如没有显示请点击栏中的【更多】）的表单并点击提交
3.向相关网络运营商反馈。`,
        },
        {
            question: '游戏大厅多开后游戏不流畅，如何解决？',
            answer: `游戏大厅提供多开功能，您可根据电脑的状况选择多开账号，建议不要超过5个。`,
        },
        {
            question: '大厅高级工具不运行',
            answer: `1.请确认电脑的配置：适合机型（win7、XP、win10）等，分辨率1280*768以上
2.请您使用360卫士，进行工具检测，对异常进行一键修复
3.若您已修复，您可以尝试使用管理员权限启动大厅、或关闭左侧小号列表。
win7：桌面选中游戏大厅，右击鼠标—以管理员身份运行
win10：桌面选中游戏大厅，右击鼠标——属性—兼容性—勾选以管理员身份运行此程序，点击确定。
4.如果以上方式还是无法解决，联系在线客服人员索要万能修复工具，进行检测修复。`,
        },
        {
            question: '大厅下载、解压失败',
            answer: `1.网络问题，建议您检查一下网络是否正常，或者切换一下网络，尝试重新下载。
2.杀毒软件拦截，您可以关闭杀毒软件或取消拦截，保证可下载加载文件。`,
        },
    ],
    account: [
        {
            question: '数据继承时，如果您忘记了空中网游戏账号',
            answer: `请您登录客服中心（https://kf.wg.360.cn/），填写空中账号忘记找回申诉。`,
        },
        {
            question: '继承时登录空中网账号，提示邮箱或密码错误',
            answer: `请您提交人工重置密码申诉（http://kf.kongzhong.com/appealemail.html），使用发送的继承密码进行继承，请您提供手持身份证露脸合照`,
        },
        {
            question: '数据继承页面',
            answer: `坦克世界数据继承页面：https://transfer.worldoftank.cn/`,
        },
        {
            question: '我的账号被其他用户继承',
            answer: `请您登录客服中心（https://kf.wg.360.cn/），提交账号数据继承争议表单`,
        },
        {
            question: '数据继承时提示该360账号已有数据',
            answer: `请您使用未登录过战舰游戏的360账号进行继承操作`,
        },
        {
            question: '忘记所继承的360账号',
            answer: `请您点击以下链接，使用空中网账号进行查询：https://wgnin.warsaga.cn/id/signin/?next=/id/openid/780859141380081403/&trust_root=https%3A//transfer.worldofship.cn`,
        },
        {
            question: '实名认证填写后是否可以修改',
            answer: `非常抱歉，实名认证填写后无法再次修改`,
        },
        {
            question: '我已经成年，账号还是防沉迷',
            answer: `请您退出战游平台左上角登录，重新登录尝试，由于同步有一定延时，请您次日查看是否解除，如还没有，请您登录客服中心（https://kf.wg.360.cn/），填写实名认证异常申诉。`,
        },
        {
            question: '如何绑定登录邮箱',
            answer: `浏览器登录i.360.cn进行更改：
①登录360账号后—点击左侧常用操作中登录邮箱【设置】———进行绑定（激活完成后即绑定成功）
②登录360账号后—点击账号安全—密保工具—登录邮箱【立即设置】———进行绑定（激活完成后即绑定成功）
③登录360账号后—点击账号安全—点击左侧登录邮箱——登录邮箱设置
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在绑定登录邮箱时，需先验证其他密保信息`,
        },
        {
            question: '如何更换登录邮箱',
            answer: `浏览器登录i.360.cn进行更改：
官网PC版：
①登录360账号后—点击左侧常用操作中登录邮箱【修改】—进行更换（激活完成后即绑定成功）
②登录360账号后—点击账号安全—密保工具—登录邮箱【修改】—进行更换（激活完成后即绑定成功）
官网手机版：
登录360账号后—点击【我的密保】—登录邮箱【修改】—进行更换（激活完成后即绑定成功）（安卓、苹果流程一致）
如无法自行更换登录邮箱，请您登录客服中心（https://kf.wg.360.cn/），填写修改登录邮箱申诉。
特殊说明：
账号如有绑定其他密保信息（密保信息可用且激活状态），在修改登录邮箱时，需先验证其他密保信息`,
        },
        {
            question: '怎样冻结/解冻账号',
            answer: `1. 账号下有绑定且激活的密保手机，并与安全卫士进行关联时：
账号卫士APP登录自己的360账号，— 点击【紧急冻结】— 点击【冻结账号】即可冻结成功解冻账号点击【解冻账号】即可解冻
2. 账号下绑定密保手机时，可发短信冻结/解冻账号：
①冻结：使用绑定手机号发送冻结指令 SDZH 至 10690133605 冻结帐号，冻结后的帐号无法登录  
②解冻：您可以使用绑定手机号发送解冻指令 JSZH 至 10690133605 解除冻结`,
        },
        {
            question: '冻结账号和解冻账号操作收费么',
            answer: `您发送冻结或解冻指令时，运营商会向您收取一条短信的费用，360是不会收取任何费用的，请知悉`,
        },
        {
            question: '如何注销账号',
            answer: `浏览器登录i.360.cn，登录360账号：
①点击【账号安全】—点击【账号服务】—点击【继续注销账号】
②选择验证方式—输入收到的验证码—账号注销确认（等待时间约20秒左右）
③点击【继续注销】—勾选“已阅读并同意《360账号注销协议》”—点击【同意并继续】
④勾选离开原因—填写联系邮箱，输入邮箱收到的验证码—页面提示申请提交成功
⑤结果会在4个工作日内发送到用户的联系邮箱（如果用户账号下未绑定密保信息，则点击【继续注销账号】后需要验证账号登录密码）`,
        },
        {
            question: '如何通过密保信息来找回密码',
            answer: `您可以复制此链接http://i.360.cn/findpwd/?account到浏览器，进行找回密码操作`,
        },
        {
            question: '如何设置/修改账号360登录用户名',
            answer: `您好，用户名一旦设置，无法修改
【注：360账号为360U开头的情况下，可以自行更改一次用户名】
设置方法如下：
1. 电脑登录 i.360.cn  登录自己的360账号后 — 点击页面右上角【未设置用户名】—进行设置用户名（用户名限制2-14个字符，支持中英文）
2. 手机登录 i.360.cn  登录自己的360账号后 — 点击【个人资料】—点击【用户名】进行设置—设置完毕后点击【保存】即可（安卓、苹果流程一致）`,
        },
        {
            question: '如何设置/修改360平台的昵称',
            answer: `您好，昵称不限制修改次数。
设置方法如下：
1、电脑登录 i.360.cn  登录自己的360账号后 — 点击头像右侧【设置昵称】进行设置（昵称限制2-14个字符，支持中英文）
2、手机登录 i.360.cn  登录自己的360账号后 — 点击【个人资料】—点击【昵称】进行设置—设置完毕后点击【保存】即可（安卓、苹果流程一致）`,
        },
    ],
};
