import React, { useEffect, useState } from 'react';
import { fetchData } from 'helpers';
import FormTypes from './formTypes';
import form404Url from 'assets/images/form404.png';
import { formDialog } from 'assets/js/dialog';
import { connect } from 'react-redux';
import {
    Form,
    //   message,
    //   DatePicker,
    //   Input,
    //   Tooltip,
    //   Icon,
    //   Cascader,
    //   Select,
    //   Row,
    //   Col,
    //   Checkbox,
    Button,
    //   AutoComplete,
} from 'antd';

function createNewFormItem(opts = {}) {
    const { data, originalObj, label, code, type, num } = opts;
    const originalCopy = Object.assign({}, originalObj);
    originalCopy.label = label;
    originalCopy.code = code;
    const index = data.fields.findIndex(item => item.type === type);
    if (type === 'address_field') {
        originalCopy.type = 'text_field';
    }
    data.fields.splice(index + num, 0, originalCopy);
}

function diyValidate(item, presence) {
    // let presence = item.validations && item.validations.presence;
    let options = {
        validateTrigger: 'onBlur',
        rules: [
            {
                required: item.validations && presence === '1',
                message: `请输入您的${item.label}`,
            },
        ],
    };
    // switch(item.type) {

    // }
    // 懒得抽离模块了 就直接if吧 麻烦。
    if (item.type === 'mobile_field') {
        options.rules.push({
            pattern: /1[^01]\d{9}/,
            message: '请输入正确的手机号',
        });
    }
    if (item.type === 'idcards_field') {
        options.rules.push({
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '请输入正确的身份证格式',
        });
    }
    if (item.type === 'email_field') {
        let reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
        options.rules.push({
            pattern: reg,
            message: '请输入正确的邮箱格式',
        });
    }
    return options;
}

const CustomizedForm = props => {
    const { uploadList, formList, history, dispatch } = props;
    const [formData, setFormData] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const { getFieldDecorator } = props.form;
    const params = new URLSearchParams(props.location.search);
    const fkey = params.get('fkey');
    const origin_tasknum = params.get('num');
    // const { submitFormData } = props;
    useEffect(() => {
        fetchData('getFormDetail', { fkey })
            .then(result => {
                if (result && Object.keys(result.data).length > 0) {
                    const data = result.data;
                    // dispatch({
                    //     type: 'RESET_ROUTE_NAME',
                    //     routeName: data.name,
                    // })
                    const address = data.fields.find(item => item.type === 'address_field');
                    const bank = data.fields.find(item => item.type === 'bank_field');
                    if (address && address.street_style === '1') {
                        dispatch({
                            type: 'STORE_ADDRESS_CODE',
                            addressCode: address.code,
                        });
                        createNewFormItem({
                            data,
                            label: '详细地址',
                            code: 'addressDetail',
                            originalObj: address,
                            type: 'address_field',
                            num: 1,
                        });
                    }
                    if (bank) {
                        bank.label = '卡号';
                        dispatch({
                            type: 'STORE_BANK_CODE',
                            bankCode: bank.code,
                        });
                        createNewFormItem({
                            data,
                            label: '银行',
                            code: 'bankLocated',
                            originalObj: bank,
                            type: 'bank_field',
                            num: 1,
                        });
                        if (bank.open_style === '1') {
                            createNewFormItem({
                                data,
                                label: '归属地',
                                code: 'bankWhereBelong',
                                originalObj: bank,
                                type: 'bank_field',
                                num: 2,
                            });
                            createNewFormItem({
                                data,
                                label: '开户行',
                                code: 'openBank',
                                originalObj: bank,
                                type: 'bank_field',
                                num: 3,
                            });
                        }
                    }
                    setFormData(data);
                } else {
                    setFormData({});
                }
            })
            .catch(err => {
                throw new Error(err);
            });
    }, [dispatch, fkey]);
    const styles = {
        // backgroundImage: 'url(' + formData.bg + ')',
        // backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: theme ? theme : 'white',
        width: '100%',
        minHeight: '710px',
        padding: '30px 40px',
        overflow: 'hidden',
        display: formData && Object.keys(formData).length > 0 ? 'block' : 'none',
    };
    const style2 = {
        // display: Object.keys(formData).length ? 'none' : 'block',
        width: '290px',
        height: '261px',
        margin: '100px auto',
    };
    const formItemLayout = {
        labelCol: {
            sm: 10,
        },
        wrapperCol: {
            sm: 12,
        },
    };
    const submitData = e => {
        e.preventDefault();
        let gameHallFormOptions = JSON.parse(window.sessionStorage.getItem('gameHallFormOptions'));
        props.form.validateFields((err, values) => {
            if (!err) {
                setSubmitLoading(true);
                const params = new URLSearchParams(props.location.search);
                const fkey = params.get('fkey');
                const options = {
                    fkey,
                    ...formList,
                    ...uploadList,
                    ...gameHallFormOptions,
                    gkey: 'wotol',
                    pkey: 'youxi',
                };
                if (props.bankCode) {
                    options[props.bankCode] = {
                        number: options[props.bankCode],
                        name: options.bankLocated,
                        ownership: options.bankWhereBelong,
                        open: options.openBank,
                    };
                }
                if (props.addressCode && options.addressDetail) {
                    options[props.addressCode] = options[props.addressCode] + '/' + options.addressDetail;
                }
                if (origin_tasknum) {
                    options.origin_tasknum = origin_tasknum;
                }
                let qucParams = ['qid', 'timestamp', 'src', 'ukey'];
                qucParams.forEach(item => {
                    let param = params.get(item);
                    if (param) {
                        options[item] = param;
                    }
                });
                fetchData('submitForm', options)
                    .then(result => {
                        if (result && result.errno === 0) {
                            dispatch({
                                type: 'RESET_FORM_LIST',
                            });
                            setTimeout(() => {
                                formDialog.success(
                                    '提交成功，请您留意邮件以及客服中心“我的问题”中查看处理结果 ',
                                    () => {
                                        history.push(`/myquiz`);
                                    },
                                );
                                setSubmitLoading(false);
                            }, 3000);
                        } else {
                            // 防止报错 不能在组建销毁后还在设置hook属性
                            setSubmitLoading(false);
                        }
                    })
                    .catch(err => {
                        Promise.reject(err);
                        setSubmitLoading(false);
                    });
            }
        });
    };
    return (
        <React.Fragment>
            <section className="bg-common border-common" style={styles}>
                <style>{`
                    .ant-input {
                        height: 34px;
                        width: 230px;
                        border-radius: 2px;
                        border-color: #6e5646;
                    }
                    .submit-button .ant-btn-primary {
                        border: 1px solid transparent;
                        border-image: linear-gradient(to right bottom, #ac8f7a, #6e5646) 1 1;
                        font-size: 20px;
                        width: 240px;
                        height: 60px;
                        line-height: 60px;
                        color: white;
                        background: linear-gradient(to top right, #836855, #584334)
                    }
                    .submit-button .ant-btn-primary:hover {
                        filter: brightness(1.3);
                        transition: all .2s;
                    }
                    .ant-form-item {
                        margin-bottom: 20px;
                        color: #fff;
                    }
                    .ant-form-item-label>label {
                        color: #fff;
                    }
                    .ant-form-item-label {
                        float: none;
                        text-align: left;
                    }
                `}</style>
                <style jsx>{`
                    .title {
                        font-size: 16px;
                        font-weight: 600;
                        margin-bottom: 30px;
                        color: #fff;
                    }
                    .form {
                        margin-top: 20px;
                    }
                    .submit-button {
                        margin-top: 36px;
                        display: ${formData.fields ? 'block' : 'none'};
                    }
                `}</style>
                <div className="title">{formData.name}</div>
                <div className="explain-text">{formData.description}</div>
                <section className="form">
                    <Form {...formItemLayout} onSubmit={submitData}>
                        {formData.fields &&
                            formData.fields.map((item, index) => {
                                let presence = item.validations && item.validations.presence;
                                if (item.type === 'bank_field' && typeof presence !== 'string') {
                                    switch (item.code) {
                                        case 'bankLocated':
                                            presence = presence.name;
                                            break;
                                        case 'bankWhereBelong':
                                            presence = presence.ownership;
                                            break;
                                        case 'openBank':
                                            presence = presence.open;
                                            break;
                                        default:
                                            presence = presence.number;
                                            break;
                                    }
                                }
                                // let options = {
                                //     rules: [
                                //         {
                                //             required: item.validations && presence === '1',
                                //             message: `请输入您的${item.label}`,
                                //         },
                                //     ],
                                // }
                                return (
                                    <Form.Item label={item.label} key={item.code}>
                                        {getFieldDecorator(
                                            item.code,
                                            diyValidate(item, presence),
                                        )(<FormTypes {...item} form={props.form} dispatch={props.dispatch} />)}
                                    </Form.Item>
                                );
                            })}
                        {/* <Form.Item> */}
                        <section className="submit-button">
                            <Button type="primary" htmlType="submit" loading={submitLoading}>
                                提交
                            </Button>
                        </section>

                        {/* </Form.Item> */}
                    </Form>
                </section>
            </section>
            {formData && !Object.keys(formData).length && (
                <section style={style2}>
                    <style>{`
                .form404img {
                    width: 100%;
                    height: 100%;
                }
            `}</style>
                    <img className="form404img" src={form404Url} alt="" />
                </section>
            )}
        </React.Fragment>
    );
};

// const filesType = ['.jpeg','.jpg','.png','.pdf','.bmp']

const ChangePhone = Form.create({
    name: 'global_state',
    onValuesChange(props, values) {
        const code = Object.keys(values)[0];
        props.dispatch({
            type: 'SET_TEXT',
            code,
            value: values[code],
        });
    },
})(CustomizedForm);

function mapStateToProps(state) {
    return {
        formList: state.formReducer.formList,
        uploadList: state.formReducer.uploadList,
        bankCode: state.formReducer.bankCode,
        addressCode: state.formReducer.addressCode,
    };
}
export default connect(mapStateToProps)(ChangePhone);
