import React from 'react';
import ServiceFunc from './serviceFunc';
import OnlineService from './onlineService';
import ContactService from './contactService';

const title1 = '我的问题';
const title2 = '自助服务';

const LeftContent = (props) => {
    // const title5 = setDetails('投诉通道','提升我们的服务质量','');
    return (
        <section>
            <style jsx>{`
                .left-content {
                    width: 218px;
                    margin: 66px 10px 0;
                    z-index: -1;
                }
            `}</style>
            <section className="left-content">
                <ServiceFunc iconName="icon-help2" title={title1} pathName='myquiz' showNews />
                <ServiceFunc iconName="icon-star" title={title2} pathName='faq' />
                <OnlineService />
                <ContactService />
            </section>
        </section>
    );
};

export default LeftContent;