import React, { useState, useEffect, forwardRef, Fragment } from 'react';
// import { connect } from 'react-redux';
import {
    // Form,
    Input,
    //   Tooltip,
    //   Icon,
    Cascader,
    Radio,
    DatePicker,
    TimePicker,
    message,
    AutoComplete,
    Upload,
    // Button,
    //   Row,
    //   Col,
    //   Checkbox,
    //   Button,
    //   AutoComplete,
} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
// import BaseIcon from 'assets/svgIcon/baseIcon';
import cityData from 'assets/js/citys';
// import { fetchData } from 'helpers';

const { TextArea } = Input;
const { Option } = AutoComplete;

// 登录平台 登录游戏 游戏区服 登录账号 角色名称
let marks = new Map([
    ['a72207a2e578e54537766d8d215e8d6f', 'pname'],
    ['c18a08139b496dc0c24a3f149603d72d', 'gname'],
    ['8a928eb6143188cfea4a4bf7c18aa8b5', 'sname'],
    ['43b561123d41f25e1aa0322468fd38a1', 'account'],
]);

let markParams = new URLSearchParams(window.location.search);

const InputType = props => {
    const { onChange, onBlur, mark } = props;
    let defaultValue;
    function inputChange(value) {
        onChange(value);
    }
    function inputBlur(value) {
        onBlur(value);
    }
    if (mark) {
        defaultValue = markParams.get(marks.get(mark));
    }
    return (
        <div>
            <Input placeholder="请输入" onChange={inputChange} onBlur={inputBlur} defaultValue={defaultValue} />
        </div>
    );
};

const SelectType = props => {
    const { onChange, onBlur } = props;
    let [emailsData, setEmailsData] = useState([]);
    const emails = [
        'gmail.com',
        'yahoo.com',
        'qq.com',
        '163.com',
        'msn.com',
        'hotmail.com',
        'aol.com',
        'ask.com',
        'live.com',
        '0355.net',
        '163.net',
        '263.net',
        '3721.net',
        'yeah',
    ];
    const options = emailsData.map((c, i) => (
        <Option key={i} className="email-option" value={c}>
            {c}
        </Option>
    ));
    let reg = /@/;
    function handleSearch(value) {
        if (value) {
            if (reg.test(value)) {
                let index = value.indexOf('@');
                let str = value.substr(index + 1);
                let regTest = new RegExp('^' + str, 'i');
                let formerValue = value.substr(0, index + 1);
                emailsData = emails
                    .filter(i => {
                        return regTest.test(i);
                    })
                    .map(e => {
                        return `${formerValue}${e}`;
                    });
            } else {
                emailsData = emails.map(item => {
                    return `${value}@${item}`;
                });
            }

            setEmailsData(emailsData);
        } else {
            setEmailsData([]);
        }
    }
    function handleChange(value) {
        onChange(value);
    }
    function inputBlur(value) {
        onBlur(value);
    }
    // function seBlur(value) {
    //     handleChange(currentValue);
    // }
    return (
        <React.Fragment>
            <style>{`
                .email-option {
                    color: #999;
                }
                .ant-select-selection--single {
                    height: 100%;
                }
                .ant-select-selection__rendered,.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
                    line-height: 34px;
                }
                .ant-select-search--inline .ant-select-search__field {
                    border-radius: 2px;
                }
                .ant-select-auto-complete.ant-select .ant-input {
                    height: 34px;
                }
            `}</style>
            <AutoComplete
                placeholder="请输入您的邮箱"
                style={{
                    width: '230px',
                    height: '34px',
                    borderRadius: '2px',
                }}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                onBlur={inputBlur}
            >
                {options}
            </AutoComplete>
        </React.Fragment>
    );
};

let qucParams = ['qid', 'timestamp', 'src', 'ukey', 'fkey'];

const setOptions = () => {
    let options = {};
    const params = new URLSearchParams(window.location.search);
    qucParams.forEach(item => {
        let param = params.get(item);
        if (param) {
            options[item] = param;
        }
    });
    return options;
};
let getZipNum = 0;
const AttachmentType = props => {
    const { code } = props;
    const [getFileList, setFileList] = useState([]);

    let attachFormats = ['jpg', 'jpeg', 'bmp', 'png', 'gif', 'rar', 'zip'];
    useEffect(() => {
        props.dispatch({
            type: 'UPLOAD_FILES',
            code,
            fileList: [],
        });
        // eslint-disable-next-line
    }, []);
    const params = {
        name: 'file',
        action: '//urm.help.360.cn/cg/form/uploadAttachments',
        headers: {
            authorization: 'authorization-text',
        },
        withCredentials: true,
        showUploadList: {
            showPreviewIcon: true,
        },
        data: { ...setOptions() },
        beforeUpload(file, fileList) {
            return new Promise((resolve, reject) => {
                const isFormat = attachFormats.some(item => file.type.includes(item));
                const isLt20m = file.size / 1024 / 1024;
                if (!isFormat) {
                    message.error('文件格式不支持，请重新上传');
                    return reject(false);
                } else if (file.type.includes('rar') || file.type.includes('zip')) {
                    if (isLt20m > 15) {
                        message.error('压缩文件不可超过15m');
                        return reject(false);
                    }
                    if (getZipNum === 0) {
                        getZipNum = 1;
                    } else {
                        message.error('压缩文件只可上传一个');
                        return reject(false);
                    }
                } else {
                    if (isLt20m > 2) {
                        message.error('附件超过2m');
                        return reject(false);
                    }
                    if (getFileList.length - getZipNum >= 15) {
                        message.error('文件上传不可超过15张');
                        return reject(false);
                    }
                }

                return resolve(true);
            });
        },
        onChange({ file, fileList }) {
            const status = file.status;
            if (status !== 'uploading') {
                //   setFileList([...fileList])
            }
            if (file.status === 'done') {
                if (file.response.errno === 0) {
                    let attachmentList = fileList.map(item => item.response.data);
                    props.dispatch({
                        type: 'UPLOAD_FILES',
                        code,
                        fileList: attachmentList,
                    });
                    setFileList(attachmentList);
                    props.onChange(1);
                } else {
                    message.error(`${file.name}${file.response.errmsg}，请删除重新上传`);
                    // this.onRemove(file);
                    return false;
                }
            }
            if (file.status === 'error') {
                //   console.log(file);
                // params.onRemove(file);
                message.error(`${file.name}上传失败`);
            }
            //   setFileList([...fileList])
        },
        onRemove(file) {
            let uniqueName = file.response.data && file.response.data.unique_name;
            if (file.type.includes('rar') || file.type.includes('zip')) {
                getZipNum = 0;
            }
            props.dispatch({
                type: 'DELETE_FILE',
                code,
                uniqueName,
            });
            let leftList = getFileList.filter(item => item.unique_name !== uniqueName);
            setFileList(leftList);
            if (leftList.length === 0) {
                props.onChange('');
            }
        },
    };
    return (
        <Fragment>
            <style>{`
                .ant-upload-list-item {
                    margin-top: 2px;
                    font-size: 12px;
                }
                .ant-upload-list {
                    width: 220px;
                }
                .ant-upload-list-item .ant-upload-list-item-info {
                    background-color: #e6f7ff;
                }
            `}</style>
            <style jsx>{`
                .file {
                    position: relative;
                    width: 78px;
                }
                .file-button {
                    height: 26px;
                    width: 78px;
                    line-height: 26px;
                    color: #999;
                    border: 1px dashed #d9d9d9;
                    font-size: 12px;
                }
                .file-name {
                    margin-left: 10px;
                    margin-right: 4px;
                }
                .file-wrap {
                    postion: relative;
                }
                .file-tip {
                    font-size: 12px;
                    position: absolute;
                    left: 100px;
                    line-height: 1.5;
                    top: 0;
                    width: 320px;
                }
            `}</style>
            <section className="file-wrap">
                {/* <div className="file">
                    <input type="file" fileid={code} accept="" style={{display:'none'}} onChange={getFileChange}/>
                    <button type="button" className="file-button" onClick={() => formClass.upload(code)}>选择文件</button>
                </div>
                <p className="file-name">{filename}</p> */}
                <Upload {...params}>
                    <button type="button" className="file-button">
                        选择文件
                    </button>
                </Upload>
                <div className="file-tip">
                    仅限上传15张不超过2M的 jpg,bmp,png,gif 图片或不超过15M的 rar,zip文件1个,视频格式建议压缩后上传
                </div>
            </section>
        </Fragment>
    );
};

const AddressType = props => {
    function addressChange(value) {
        const onChange = props.onChange;
        onChange(value.join('/'));
    }
    return (
        <div style={{ width: '230px' }}>
            <Cascader options={cityData} onChange={addressChange} placeholder="请选择地址" />
        </div>
    );
};

const RadioButton = props => {
    const { choices } = props;
    function inputChange(value) {
        const onChange = props.onChange;
        onChange(value);
    }
    return (
        <Radio.Group>
            {choices.map((item, index) => {
                return (
                    <Radio value={item.value} key={item.id} onChange={inputChange}>
                        {item.value}
                    </Radio>
                );
            })}
            {/* <Radio value="a">item 1</Radio>
            <Radio value="b">item 2</Radio>
            <Radio value="c">item 3</Radio> */}
        </Radio.Group>
    );
};

const DateType = (props, ref) => {
    function inputChange(value, valueString) {
        const onChange = props.onChange;
        onChange(valueString);
    }
    return <DatePicker onChange={inputChange} locale={locale} />;
};

const TimeType = props => {
    function inputChange(value, valueString) {
        const onChange = props.onChange;
        onChange(valueString);
    }
    const format = 'HH:mm';
    return (
        <section>
            <style>{`
                .ant-time-picker-input {
                    height: 34px;
                    border-radius: 2px;
                }
            `}</style>
            <TimePicker style={{ width: '230px' }} placeholder="请选择时间" onChange={inputChange} format={format} />
        </section>
    );
};

// const PageType = (props) => {
//     return (
//         <p>页码</p>
//     )
// }

const TextAreaType = props => {
    function inputChange(value) {
        const onChange = props.onChange;
        onChange(value);
    }
    return (
        <div>
            <style>{`
                textarea.ant-input {
                    width: 270px;
                    height: 75px !important;
                }
                .ant-cascader-menus {
                    font-size: 12px;
                    height: 150px;
                }
                .ant-cascader-menu {
                    height: 150px;
                }
            `}</style>
            <TextArea onChange={inputChange} />
        </div>
    );
};

const typesToForm = new Map([
    ['text_field', InputType],
    ['attachment_field', AttachmentType],
    ['address_field', AddressType],
    ['text_area', TextAreaType],
    ['radio_button', RadioButton],
    ['idcards_field', InputType],
    ['date_field', DateType],
    ['time_field', TimeType],
    ['email_field', SelectType],
    ['mobile_field', InputType],
    ['bank_field', InputType],
    ['page_break', InputType],
]);

let FormTypes = (props, ref) => {
    const { type } = props;
    const style = {
        // height: '18px',
        width: '230px',
        lineHeight: '18px',
        fontSize: '12px',
        color: '#666',
        marginTop: '2px',
    };
    let FormType = typesToForm.get(type);
    if (!FormType) {
        FormType = InputType;
    }
    return (
        <span>
            <FormType {...props} />
            <div style={style}>{props.notes}</div>
            {/* {type === 'attachment_field' && <div>dsadsad</div>} */}
        </span>
    );
};

let Forms = forwardRef(FormTypes);

export default Forms;
