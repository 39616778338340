import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import QuickBoot from './quickBoot';
import Recharge from './recharge';
import quizData from './quizData';

const types = [
    { type: 'account', name: '账号问题' },
    { type: 'login', name: '登录问题' },
    { type: 'recharge', name: '充值问题' },
    { type: 'game', name: '游戏问题' },
];

// 自助服务
const Faq = props => {
    const { TabPane } = Tabs;
    const { activeKey } = props;
    const changeActiveKey = activeKey => {
        props.dispatch({
            type: 'CHANGE_QUESTION_TYPE',
            activeKey,
        });
    };
    return (
        <section>
            <style>{`
                .card-container .ant-tabs-content {
                background: #fff;
                border: 1px solid #d9d9d9;
                border-top: none;
                }
                .card-container > .ant-tabs-card > .ant-tabs-content {
                    border: none;
                    background: none;
                }
                .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                
                .card-container > .ant-tabs-card > .ant-tabs-bar {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-color: #01080f;
                }
                
                .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
                border-color: #d9d9d9;
                background: #f2f2f2;
                padding: 0 28px;
                margin-right: 0;
                font-size: 16px;
                }
                
                .card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
                border-color: #d9d9d9;
                border-bottom-color: #fff;
                background: #fff;
                }
                .card-container .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
                    width: 152px;
                    height: 50px;
                    line-height: 50px;
                    border-radius: 0;
                    text-align: center;
                    background-color: #463c34;
                    color: #fff;
                    border: 2px solid transparent;
                    border-image: linear-gradient(to right bottom, #826854, #6f5341) 1 1;
                }
                .card-container .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:not(:last-child) {
                    margin-right: 10px;
                }
                .card-container .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active,
                .card-container .ant-tabs-nav .ant-tabs-tab:hover {
                    color: #fff;
                    border: 2px solid transparent;
                    border-image: linear-gradient(to right, #f89c67, #6f5341) 1 1;
                    background-image: linear-gradient(to top right, #af7c5c, #443a32)
                }
                .card-container .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
                    height: auto;
                }
            `}</style>
            <div className="card-container">
                <Tabs type="card" activeKey={activeKey} onChange={changeActiveKey}>
                    <TabPane tab="快速引导" key="1">
                        {types.map((item, index) => {
                            return (
                                <QuickBoot
                                    content={quizData[item.type].slice(0, 5)}
                                    title={item.name}
                                    key={index}
                                    activeKey={String(index + 2)}
                                    quizType={item.type}
                                />
                            );
                        })}
                    </TabPane>
                    {types.map((item, index) => {
                        return (
                            <TabPane tab={item.name} key={String(index + 2)}>
                                <Recharge questions={quizData[item.type]} type={item.type} />
                            </TabPane>
                        );
                    })}
                </Tabs>
            </div>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        activeKey: state.questionReducer.activeKey,
    };
}

export default connect(mapStateToProps)(Faq);
