import React from 'react';
import imgUrl from 'assets/images/serviceAvatar.png';
import { connect } from 'react-redux';

const toOnlineConsultation = () => {
    window.open('//profile.wg.360.cn/api/sso/loginIM?display=wot');
};

const CustomerService = () => {
    return (
        <div>
            <style jsx>{`
                .onlineService {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 60px;
                    background-color: #4e4238n;
                }
                .top {
                    height: 38px;
                    font-size: 14px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    background-color: #443a32;
                }
                .avatar {
                    width: 80px;
                    height: 80px;
                }
                .bottom {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    height: 170px;
                }
                .avatar img {
                    width: 100%;
                    height: 100%;
                }
                .button {
                    width: 175px;
                    height: 38px;
                    line-height: 38px;
                    text-align: center;
                    border: 1px solid transparent;
                    background: linear-gradient(#d45501, #9b290a);
                    transition: all 0.3s;
                }

                .button:hover {
                    filter: brightness(1.3);
                }
            `}</style>
            <section className="onlineService border-common" onClick={() => toOnlineConsultation()}>
                <div className="top">在线客服</div>
                <div className="bottom">
                    <div className="avatar">
                        <img src={imgUrl} alt="" />
                    </div>
                    <button className="button">发起咨询</button>
                </div>
            </section>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        isShowOnlineService: state.showReducer.isShowOnlineService,
    };
}
export default connect(mapStateToProps)(CustomerService);
