import React from 'react';
import Header from './header/header';
import LoginBar from './login';
import Content from './content/content';
import Footer from './footer';
import { Provider } from 'react-redux';
// import { formDialog } from 'assets/js/dialog';
import store from './store';
import { BrowserRouter as Router } from 'react-router-dom';
import bg from './assets/images/bg.jpg';



const App = (props) => {
    // let isShowCover = window.sessionStorage.getItem('isShowCover');
    // if (isShowCover) {
    //     store.dispatch({
    //         type: 'SET_COVER',
    //         isShowCover: false
    //     })
    // }
    // formDialog.success('提交成功，我们会在1-3个工作日内回复您，请在“我的问题”列表查看处理进度。', () => {})
    return (
        <Router>
            <Provider store={store}>
                <style jsx>{`
                    .container {
                        background: url(${bg}) no-repeat center top;
                        min-height: 944px;
                        color: #fff;
                    }
                `}
                </style>
                <section className="container">
                    <LoginBar />
                    <Header />
                    <Content />
                </section>
                <Footer style={{
                    position: 'absolute',
                    bottom: 0
                }} />
            </Provider>
        </Router>

    );
};

export default App;