import React, { useEffect, useState } from 'react';
import { fetchData } from 'helpers';
// import { HTMLDecode } from 'assets/js/utils';

const Announcement = (props) => {
    const [noticeDetail, setNoticeDetail] = useState({});
    let param = props.location.search;
    const id = new URLSearchParams(param).get('noticeId');
    useEffect(() => {
        fetchData('getNoticeDetail', { id, num: 200 }).then((result) => {
            setNoticeDetail(result.data);
        }).catch((err) => {

        });
    }, [id]);
    return (
        <section>
            <style>{`
                .announcement-content {
                    display: ${noticeDetail.content ? 'block' : 'none'};
                }
                .announcement-content img {
                    max-width: 100%;
                    margin:0 auto;
                }
            `}</style>
            <style jsx>{`
                .container {
                    padding: 20px 40px;
                    box-sizing: border-box;
                    height: 630px;
                    overflow-y: auto;
                    color: #fff
                }
                .title {
                    font-weight: 600;
                    font-size: 16px;
                    color: #fff
                }
                .time {
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
                .content .text{
                    line-height: 22px;
                    margin-bottom: 18px;
                }
                .content .start {
                    line-height: 40px;
                }
                .image {
                    height: 200px;
                    background: #d8d8d8;
                }
                .footer {
                    margin-top: 50px;
                    text-align: right;
                }
                .tip2 {
                    margin-top: 6px;
                }
            `} </style>
            <section className="container border-common bg-common">
                <h2 className="title">{noticeDetail.title}</h2>
                <p className="time">{noticeDetail.start_time}</p>
                {/* <div className="announcement-content" dangerouslySetInnerHTML={{__html: HTMLDecode(noticeDetail.content)}} /> */}
                <div className="announcement-content" dangerouslySetInnerHTML={{ __html: noticeDetail.content }}></div>
                {/* <div className="footer">
              <p className="tip1">360游戏客服中心</p>
              <p className="tip2">{noticeDetail.start_time}</p>
            </div> */}
            </section>
        </section>
    );
};

export default Announcement;