import React from 'react';
import contactServiceBg from 'assets/images/contactService_bg.png';
import iconPhone2 from 'assets/images/icon-phone2.png';
import iconEmail from 'assets/images/icon-email.png';

const ContactService = () => {
    return (
        <div>
            <style jsx>{`
                .contactService {
                    margin-top: 20px;
                    font-size: 16px;
                    background: url(${contactServiceBg}) no-repeat center top;
                }

                h2 {
                    line-height: 40px;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                }

                .contactService-item {
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                    line-height: 36px;
                }

                .icon {
                    margin-right: 10px;
                }

                .icon-phone2 {
                    width: 16px;
                    height: 16px;
                    background-image: url(${iconPhone2});
                }
                .icon-email {
                    width: 16px;
                    height: 12px;
                    background-image: url(${iconEmail});
                }
            `}</style>
            <div className="contactService">
                <h2>联系客服</h2>
                <div className="contactService-item">
                    <i className="icon icon-phone2"></i>0536-3565177
                </div>
            </div>
        </div>
    );
};

export default ContactService;
