import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import newsInformUrl from 'assets/images/newsInform.png';
import iconHelp2 from 'assets/images/icon-help2.png';
import iconStar from 'assets/images/icon-star.png';
import { fetchData } from 'helpers';

class ServiceFunc extends Component {
    state = {
        newsNumber: 0,
    };
    toRoute(history, pathName) {
        history.push('/' + pathName);
    }
    componentDidMount() {
        if (!this.props.isLogin) return;
        if (this.props.showNews && this.props.history.location.pathname !== '/myquiz/quizdetail') {
            this.getNewMessagesNumber();
        }
    }
    getNewMessagesNumber() {
        fetchData('getNewMessagesNumber')
            .then(result => {
                if (result) {
                    this.props.dispatch({
                        type: 'CHANGE_SHOW_NEWS',
                        isShowNews: result.data.total,
                    });
                }
            })
            .catch(err => {});
    }
    render() {
        const { iconName, title, pathName, location, showNews } = this.props;
        // const search = new URLSearchParams(location.search).get('type');
        return (
            <section>
                <style jsx>
                    {`
                        .box {
                            height: 80px;
                            border: 2px solid #987e6c;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #443a32;
                            box-sizing: border-box;
                            position: relative;
                            cursor: pointer;
                        }
                        .box.active {
                            background: linear-gradient(to top right, rgba(255, 183, 135, 0.5), rgba(1, 9, 16, 0.5));
                            border-image: linear-gradient(to right, #ffd5b9, #987e6c) 1 1;
                        }
                        .service-details {
                            margin-left: 18px;
                        }
                        .title {
                            text-align: right;
                            font-weight: bold;
                            font-size: 20px;
                            margin-bottom: 5px;
                            color: #fff;
                        }
                        .news-inform {
                            position: absolute;
                            right: -6px;
                            top: 0;
                            width: 63px;
                            height: 30px;
                        }
                        .news-inform img {
                            width: 100%;
                            height: 100%;
                        }
                        .news-inform .font-new {
                            position: absolute;
                            right: 50%;
                            top: 1px;
                            white-space: nowrap;
                            transform: translateX(50%);
                            font-size: 12px;
                            color: white;
                            font-weight: bold;
                        }
                        .icon-help2 {
                            width: 24px;
                            height: 24px;
                            background: url(${iconHelp2});
                        }
                        .icon-star {
                            width: 23px;
                            height: 26px;
                            background: url(${iconStar});
                        }
                    `}
                </style>
                <section
                    className={`box ${location.pathname === `/${pathName}` ? 'active' : ''}`}
                    onClick={() => this.toRoute(this.props.history, pathName)}
                >
                    <i className={'icon ' + iconName}></i>
                    <ul className="service-details">
                        <li className="title">{title}</li>
                    </ul>
                    {showNews && Boolean(this.props.isShowNews) && (
                        <div className="news-inform">
                            <img src={newsInformUrl} alt="" />
                            <div className="font-new">新消息</div>
                        </div>
                    )}
                </section>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        isShowNews: state.showReducer.isShowNews,
        isLogin: state.showReducer.isLogin,
    };
}
export default connect(mapStateToProps)(withRouter(ServiceFunc));
