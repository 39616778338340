import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchData } from 'helpers';

const Report = props => {
    const { content } = props;
    const style = {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginTop: '12px',
    };
    return (
        <section style={style}>
            <style jsx>{`
                .report-content {
                    width: 300px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 8px;
                    line-height: 1.4;
                    flex: 1;
                }
            `}</style>
            <p className="report-content add-pointer">{content}</p>
        </section>
    );
};

const toRoute = (history, index, item) => {
    item && fetchData('postFaqStatistics', { id: item.id });
    history.push(`/hotquiz?quizindex=${index}`);
};

// 公告板块
const Announcement = props => {
    const [noticeList, setNoticeList] = useState([]);
    useEffect(() => {
        fetchData('getFaqList', { limit: 5, gkey: 'wotol' })
            .then(res => {
                setNoticeList(res.data);
            })
            .catch(err => {});
    }, []);
    return (
        <section>
            <style jsx>{`
                .announcement {
                    width: 472px;
                    height: 208px;
                    background: #4e4238;
                }
                .title-fence {
                    height: 40px;
                    background: #443a32;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    overflow: hidden;
                    font-size: 14px;
                    color: #fff;
                }
                .report-list {
                    height: 168px;
                    padding: 0 15px 15px;
                    box-sizing: border-box;
                    color: #fff;
                }
                .more {
                    width: 84px;
                    height: 40px;
                    color: #fff;
                    line-height: 40px;
                    text-align: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0.8;
                }
                .more .more-text {
                    display: inline-block;
                }
            `}</style>
            <section className="announcement border-common">
                <div className="title-fence">
                    <span style={{ fontWeight: 'bold', marginLeft: '6px', fontSize: '16px' }}>热门问题</span>
                    <div className="more add-pointer" onClick={() => toRoute(props.history, 0)}>
                        <span className="more-text">更多</span>
                    </div>
                </div>
                <ul className="report-list">
                    {noticeList.map((item, index) => {
                        return (
                            <li key={index} onClick={() => toRoute(props.history, index, item)}>
                                <Report content={item.title} />
                            </li>
                        );
                    })}
                </ul>
            </section>
        </section>
    );
};

export default withRouter(Announcement);
