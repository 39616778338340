import React from 'react';
import { connect } from 'react-redux';

// 快速引导
const QuickBoot = props => {
    const { title, content, activeKey, quizType } = props;
    const chooseQuiz = index => {
        props.dispatch({
            type: 'CHANGE_QUESTION_INDEX',
            quizType,
            quizTypeIndex: index,
        });
        props.dispatch({
            type: 'CHANGE_QUESTION_TYPE',
            activeKey,
        });
    };
    const style = {
        width: '476px',
        border: '1px solid transparent',
        borderImage: 'linear-gradient(to right, #a58975, #705848) 1 1',
        marginBottom: '10px',
        backgroundColor: '#4e4238',
    };
    return (
        <section style={style}>
            <style jsx>{`
                .title {
                    height: 40px;
                    line-height: 40px;
                    color: #fff;
                    font-weight: bold;
                    margin-bottom: 8px;
                    font-size: 16px;
                    text-align: center;
                    background-color: #26221f;
                }
                .single {
                    position: relative;
                    padding-left: 26px;
                    margin-bottom: 8px;
                }
                .content {
                    font-size: 14px;
                    color: #fff;
                }
            `}</style>
            <p className="title">{title}</p>
            <ul className="list">
                {content.map((item, index) => {
                    return (
                        <li className="single" key={index} onClick={() => chooseQuiz(index)}>
                            <p className="content add-pointer">{item.question}</p>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        // questionIndex: state.questionReducer.questionIndex
    };
}

export default connect(mapStateToProps)(QuickBoot);
