
const initialState = {
    uploadList: {},
    formList: {},
    bankCode: '',
    addressCode: '',
}

function formReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPLOAD_FILES':
            return {
                ...state,
                uploadList: { 
                    ...state.uploadList,
                    [action.code]: action.fileList
                }
            }
        case 'DELETE_FILE':
            let uniqueName = action.uniqueName;
            let fileList = state.uploadList[action.code] && state.uploadList[action.code].filter(item => item.unique_name !== uniqueName);
            return {
                ...state,
                uploadList: { 
                    ...state.uploadList,
                    [action.code]: fileList
                }
            }
        case 'SET_TEXT':
            return {
                ...state,
                formList: { 
                    ...state.formList,
                    [action.code]: action.value,
                }
            }
        case 'STORE_BANK_CODE':
            return {
                ...state,
                bankCode: action.bankCode,
            }
        case 'STORE_ADDRESS_CODE':
            return {
                ...state,
                addressCode: action.addressCode,
            }
        case 'RESET_FORM_LIST':
            return initialState
        case 'RESET_ROUTE_NAME':
            return {
                ...state,
                routeName: action.routeName
            }
        default:
        break;
    }
    return state
}



export default formReducer