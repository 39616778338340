import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import LeftPages from './leftPages';
import CenterPages from './centerPages';
import imgUrl from 'assets/images/know.png';
import spiral from 'assets/images/spiral.png';
import DetailServices from './detailServices';
// import { fetchData } from 'helpers';
// import QuizDetail from 'content/detailServices/myquiz/quizDetail';

const Cover = ({ dispatch }) => {
    window.sessionStorage.setItem('isShowCover', '1');
    let know = () => {
        dispatch({
            type: 'SET_SUBMIT_COVER',
            submitCover: false,
        });
    };
    return (
        <section>
            <style jsx>
                {`
                    .cover {
                        position: fixed;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0, 0, 0, 0.4);
                        z-index: 10;
                        overflow-y: hidden;
                    }
                    .zhanwei {
                        width: 242px;
                        height: 300px;
                    }
                    .center {
                        width: 1200px;
                        position: absolute;
                        top: 100px;
                        z-index: 20;
                        display: flex;
                    }
                    .tip {
                        width: 280px;
                        height: 92px;
                        background: rgba(0, 0, 0, 0.6);
                        color: white;
                        border: 2px solid #fff;
                        font-size: 14px;
                        text-align: center;
                        line-height: 2.6;
                        padding: 6px 0;
                    }
                    .know {
                        width: 138px;
                        height: 40px;
                        position: absolute;
                        right: 0;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    .spiral {
                        width: 72px;
                        height: 68px;
                        position: absolute;
                        top: 100px;
                        left: 400px;
                    }
                `}
            </style>
            <section className="cover"></section>
            <div className="center">
                <div className="zhanwei"></div>
                <div className="tip">
                    点击标题，提交问题
                    <br /> 问题解决更快哦~
                </div>
                <div className="know" onClick={know}>
                    <img src={imgUrl} alt="" />
                </div>
                <div className="spiral">
                    <img src={spiral} alt="" />
                </div>
            </div>
        </section>
    );
};

const Content = props => {
    let isIndex = window.location.pathname === '/';
    let showCover =
        props.submitCover || !window.sessionStorage.getItem('isShowCover');
    return (
        <section style={{ minHeight: '710px' }}>
            <style jsx>
                {`
                    .content-container {
                        padding-bottom: 50px;
                        width: 1200px;
                        margin: 0 auto;
                        display: flex;
                        position: relative;
                    }
                `}
            </style>
            <section className="content-container">
                {showCover && isIndex && <Cover dispatch={props.dispatch} />}
                <LeftPages />
                <Route exact path="/" component={CenterPages} />
                <Route path="/:question" component={DetailServices} />
            </section>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        isShowOnlineService: state.showReducer.isShowOnlineService,
        isShowCover: state.showReducer.isShowCover,
        submitCover: state.showReducer.submitCover,
    };
}

export default connect(mapStateToProps)(Content);
