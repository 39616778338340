import React from 'react';
import { connect } from 'react-redux';
import iconHelp from '../../../assets/images/icon-help.png';

const LiSingle = (props) => {
    const { question, answer, index, num, dispatch, quizType } = props;
    const chooseQuizIndex = () => {
        dispatch({
            type: 'CHANGE_QUESTION_INDEX',
            quizType,
            quizTypeIndex: index,
        });
    };
    return (
        <section>
            <style jsx>{`
                .question {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    margin-bottom: 8px;
                }
                .title {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #fff;
                }
                .question-content {
                    padding: 15px 20px;
                    white-space: pre-wrap;
                    display: ${num === index ? 'block' : 'none'};
                    color: #fff;
                }
            `}</style>
            <div className="question add-pointer" onClick={chooseQuizIndex}>
                <i className="icon"><img src={iconHelp} alt="" /></i>
                <div className="title">{question}</div>
            </div>
            <div className="question-content">
                {answer}
            </div>
        </section>
    );
};

const Recharge = (props) => {
    const { questions, type, questionActivesIndex } = props;
    // const [num,setNum] = useState(0);
    const style = {
        boxSizing: 'border-box',
        padding: '0 20px',
        width: '100%',
        height: '630px',
        overflowY: 'auto',
    };
    return (
        <section className="bg-common border-common" style={style}>
            <style jsx>{`
                .quiz {
                    margin-top: 15px;
                }
            `}</style>
            <ul className="content">
                {questions.map((item, index) => {
                    return (<li className="quiz" key={index}>
                        <LiSingle
                            question={item.question}
                            answer={item.answer}
                            index={index}
                            num={questionActivesIndex[type]}
                            dispatch={props.dispatch}
                            quizType={type}
                        />
                    </li>);
                })}
            </ul>
        </section>
    );
};

function mapStateToProps(state) {
    return {
        questionActivesIndex: state.questionReducer.questionActivesIndex
    };
}

export default connect(mapStateToProps)(Recharge);