import React from 'react';
import { connect } from 'react-redux';


// const toOnlineConsultation = (history) => {
//     history.push('/onlineconsultationim')
// }

// 内容中间块的上面的标题部分
const CenterTitle = (props) => {
    const { breads, history, paths } = props;
    function goBread(index) {
        if (index === paths.length - 1) return;
        if (paths[index] === '/accountproblems') {
            let accountId = window.sessionStorage.getItem('accountId');
            history.push(`${paths[index]}?accountid=${accountId ? accountId : '1'}`);
            return;
        }
        history.push(`${paths[index]}`);
    }
    let isQuizDetail = history.location.pathname.split('/').includes('quizdetail');
    function toComplaint() {
        let params = new URLSearchParams(history.location.search);
        history.push(`/myquiz/accountdetail?fkey=${props.complaintfkey}&num=${params.get('num')}&quizTitle=${params.get('quizTitle')}`);
    }
    return (
        <section>
            <style jsx>{`
                .title {
                    font-size: 24px;
                    color: #666;
                }
                .breads {
                    display: flex;
                    align-items: center;
                    height: 66px;
                    color: #fff;
                    font-size: 16px;
                    position: relative;
                }
                .bread-crumb {
                    margin-right: 4px;
                }
                .complaint {
                    position: absolute;
                    right: 0;
                }
                .change-online {
                    position: absolute;
                    right: 60px;
                }
            `}</style>
            <ul className="breads">
                {
                    breads.map((item, index) => {
                        return <li className="bread-crumb add-pointer" key={index} onClick={() => goBread(index)}>{item} {breads.length - 1 === index ? '' : '>'}</li>;
                    })

                }
                {isQuizDetail && props.complaintfkey && <li className="bread-crumb add-pointer complaint" onClick={toComplaint}>投诉</li>}
            </ul>

        </section>
    );
};

function mapStateToProps(state) {
    return {
        complaintfkey: state.questionReducer.complaintfkey,
    };
}
export default connect(mapStateToProps)(CenterTitle);