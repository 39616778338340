import React from 'react';
import { Radio, Modal, Button, message } from 'antd';
import Dialog from 'assets/js/dialog';
import { fetchData } from 'helpers';

const radios = ['非常满意', '满意', '一般', '不满意', '非常不满意'];
const improves = ['客服态度', '处理时效', '处理结果'];

const RadioGroup = props => {
    const { status, setStatus, setSuggestion } = props;
    return (
        <div>
            <style>{`
                .ant-modal {
                    color: #fff;
                }
                .ant-radio-group {
                    display: flex;
                    justify-content: space-between;
                }
                .evaluate .ant-modal {
                    padding-bottom: 0;
                    width: 520px !important;
                    border: 1px solid transparent;
                    border-image: linear-gradient(to right, #b09784, #735b4b) 1 1;
                }
                .ant-modal-content {
                    background-color: #574a3f;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #06e3ee;
                    border-color: #06e3ee;
                }
                .ant-radio-checked::after {
                    border: 1px solid #06e3ee;
                }
                .ant-radio-wrapper {
                    color: #fff;
                }
                .ant-radio-inner {
                    background-color: #01080f;
                }
                .ant-radio-checked .ant-radio-inner,
                .ant-radio-wrapper:hover .ant-radio, 
                .ant-radio:hover .ant-radio-inner, 
                .ant-radio-input:focus + .ant-radio-inner,
                .ant-checkbox-checked::after {
                    border-color: #f5aa51;
                }
                .ant-radio-inner::after,
                .ant-checkbox-indeterminate .ant-checkbox-inner::after {
                    background-color: #f5aa51;
                }
                .evaluate .ant-modal-body {
                    padding: 24px;
                }
                .evaluate .ant-modal-footer {
                    display: flex;
                    border-top: none;
                    justify-content: space-between;
                    padding: 10px 76px 20px;
                }
                .evaluate .ant-modal-footer .ant-btn {
                    width: 175px;
                    height: 38px;
                    border: 1px solid transparent;
                    border-image: linear-gradient(to right bottom, #b09784, #6e5646) 1 1;
                    color: #fff;
                    background: linear-gradient(to top right, #ad8f79, #ad8f79);
                    transition: all .3s;
                }
                .evaluate .ant-modal-footer .ant-btn:hover {
                    filter: brightness(1.2);
                }
                .evaluate .ant-modal-footer .ant-btn-primary {
                    border: 1px solid transparent;
                    border-image: linear-gradient(to right bottom, #b09784, #6e5646) 1 1;
                    background: linear-gradient(to top right, #ad8f79, #ad8f79);
                }
                .improve-radios {
                    margin: 0 auto;
                    padding: 0 80px;
                    display: flex;
                    justify-content: space-between;
                }
            `}</style>
            <style jsx>{`
                .improve {
                    margin-top: 28px;
                    display: ${status >= 2 ? 'block' : 'none'};
                }
                .improve-title {
                    text-align: center;
                    margin-bottom: 12px;
                }
            `}</style>
            <div>
                <Radio.Group className="evaluate-radios" defaultValue={0} onChange={e => setStatus(e.target.value)}>
                    {radios.map((key, i) => (
                        <Radio key={i} value={i}>
                            {key}
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
            <div className="improve">
                <p className="improve-title">我们需要改进的方向是：</p>
                <Radio.Group className="improve-radios" onChange={e => setSuggestion(e.target.value)}>
                    {improves.map((key, i) => (
                        <Radio key={i} value={i}>
                            {key}
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
        </div>
    );
};

class EvaluateModal extends React.Component {
    state = {
        loading: false,
        visible: false,
        status: 0,
        suggestion: '',
        isAssess: this.props.isAssess,
    };
    setStatus = value => {
        this.setState({
            status: value,
        });
    };
    setSuggestion = value => {
        this.setState({
            suggestion: value,
        });
    };
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        if (this.state.status > 1 && !String(this.state.suggestion)) {
            message.error('请至少选择一项我们需要改进的方向');
            return;
        }
        this.setState({ loading: true });
        // setTimeout(() => {
        //   this.setState({ loading: false, visible: false });
        //   Dialog.success('感谢您的评价！')
        // }, 1000);
        const options = {
            num: this.props.num,
            level: String(this.state.status + 1),
            improve: this.state.status > 1 ? String(this.state.suggestion + 1) : '',
        };
        fetchData('submitEvaluate', options).then(result => {
            if (result) {
                this.setState({
                    visible: false,
                    loading: false,
                    isAssess: '0',
                });
                Dialog.success('感谢您的评价！');
            }
            // }).catch((err) => {
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    render() {
        const { visible, loading } = this.state;
        // const isAssess = this.props.isAssess;
        return (
            <div>
                <style>{`
                    .evaluate .ant-modal-header {
                        border-radius: 0;
                        border: none;
                        padding: 8px 0;
                        background: #01080f;
                    }
                    .evaluate .ant-modal-title {
                        text-align: center;
                        color: #fff;
                    }
                `}</style>
                <div
                    onClick={this.showModal}
                    style={{
                        color: '#06e3ee',
                        display: this.state.isAssess !== '0' ? 'block' : 'none',
                    }}
                >
                    评价
                </div>
                <Modal
                    visible={visible}
                    title="请对我们的服务作出评价"
                    onOk={this.handleOk}
                    centered
                    onCancel={this.handleCancel}
                    // mask={false}
                    wrapClassName="evaluate"
                    closable={false}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
                            提交
                        </Button>,
                    ]}
                >
                    <RadioGroup
                        status={this.state.status}
                        setStatus={this.setStatus}
                        setSuggestion={this.setSuggestion}
                    />
                </Modal>
            </div>
        );
    }
}

export default EvaluateModal;
